import React, { useState, useEffect, useContext } from "react";
import axios from "./axiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import { LoaderContext } from "./LoaderContext";
import { APIURL, DUTY_TYPE } from "../Constants";

import resultBackgroungImg from "../images/background/2.jpg";
import carImageThumb from "../images/car-thumbs/car-image.png";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const BookingPage = () => {
  const navigate = useNavigate();
  const { setHideLoader } = useContext(LoaderContext);
  const queryParams = useQuery();
  const encodedData = queryParams.get("data");
  // const formData = encodedData ? JSON.parse(atob(encodedData)) : {};
  const [car, setCar] = useState(null);
  const [formData, setFormData] = useState(
    encodedData
      ? {
          ...JSON.parse(atob(encodedData)),
          name: "",
          email: "",
          phone: "",
          remark: "",
          honeypot: "",
        }
      : {},
  );
  const [errors, setErrors] = useState({});

  const [modalContent, setModalContent] = useState("");
  const [showModal, setShowModal] = useState(false);

  const validateForm = () => {
    let formErrors = {};
    if (!formData.name) {
      formErrors.name = "Name is required";
    } else if (formData.name.length < 3) {
      formErrors.name = "Name must be at least 3 characters";
    }
    if (!formData.email) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email address is invalid";
    }
    if (!formData.phone) {
      formErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      formErrors.phone = "Phone number must be exactly 10 digits";
    }
    return formErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = validateForm();
    setErrors(formErrors);
    if (Object.keys(formErrors).length === 0 && !formData.honeypot) {
      // Submit the form if there are no errors and honeypot is empty
      setModalContent("Booking in progress...");
      setShowModal(true);
      setHideLoader(false);
      try {
        const response = await axios.post(`${APIURL}book_car`, formData);
        setHideLoader(true);
        if (response.data.bookingRef) {
          setModalContent(`Booking successful! Reference: ${response.data.bookingRef}`);
          navigate(`/confirmation?reference=${response.data.bookingRef}`);
        } else {
          setModalContent("Booking failed! An error occurred.");
        }
      } catch (err) {
        setHideLoader(true);
        if (err.response) {
          console.error(err.response.data.message || "An error occurred");
        } else {
          console.error("An error occurred");
        }
        setModalContent(
          `Booking failed! ${err.response ? err.response.data.message : "An error occurred"}`,
        );
      }
    }
  };

  useEffect(() => {
    if (formData && DUTY_TYPE?.[formData?.subType] && formData?.startAddress !== null) {
      setCar(formData);
    }
  }, [formData?.startAddress]);

  console.log("MYLOG formData ", formData);

  return (
    <>
      {showModal && (
        <div
          className="modal fade show"
          style={{ display: "block" }}
          id="bookingModal"
          tabIndex="-1"
          aria-labelledby="bookingModalLabel"
          aria-hidden="true"
          data-bs-keyboard="false"
          data-bs-backdrop="static"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="bookingModalLabel">
                  Booking
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <div className="modal-body">{modalContent}</div>
            </div>
          </div>
        </div>
      )}

      <section id="subheader" className="cars-result-header jarallax text-light">
        <img src={resultBackgroungImg} className="jarallax-img" alt="" />
      </section>

      <section id="section-car-details">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-8">
              <div id="section-car-details-left">
                <div className="de-box mb25">
                  <form name="contactForm" id="contact_form" method="post" onSubmit={handleSubmit}>
                    <p>
                      <strong>Almost done!</strong> Enter your details and complete your booking
                      now.
                    </p>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="de-spec">
                          <div className="d-row">
                            {DUTY_TYPE?.[formData?.subType] && (
                              <p>
                                <strong>Duty Type:</strong> {DUTY_TYPE?.[formData?.subType]}
                              </p>
                            )}
                            {car?.startAddress && (
                              <p>
                                <strong>Pickup:</strong> {car?.startAddress}
                              </p>
                            )}
                            {car?.endAddress && (
                              <p>
                                <strong>Drop:</strong> {car?.endAddress}
                              </p>
                            )}
                            {car?.durationText && car?.duration > 0 && (
                              <p>
                                <strong>Duration:</strong> {car?.durationText} Approx
                              </p>
                            )}
                            {car?.distanceText && car?.distance > 0 && (
                              <p>
                                <strong>Distance:</strong> {car?.distanceText} Approx
                              </p>
                            )}
                            {car?.localPackagesStr && (
                              <p>
                                <strong>Package:</strong> {car?.localPackagesStr}
                              </p>
                            )}
                            {car?.pickupDate && (
                              <p>
                                <strong>Pickup Date & Time:</strong> {car?.pickupDate}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="spacer-20"></div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="de-item-list mb30">
                          <div className="d-img">
                            <img src={carImageThumb} className="img-fluid" alt={car?.modelName} />
                          </div>
                          <div className="d-info">
                            <div className="d-text">
                              <h4>{car?.modelName}</h4>
                              <div className="d-atr-group">
                                <ul className="d-atr">
                                  <li>
                                    <span>Seats:</span>4
                                  </li>
                                  <li>
                                    <span>Doors:</span>4
                                  </li>
                                  <li>
                                    <span>Fuel:</span>Petrol
                                  </li>
                                  <li>
                                    <span>Engine:</span>3000
                                  </li>
                                  <li>
                                    <span>Drive:</span>4x4
                                  </li>
                                  <li>
                                    <span>Type:</span>Hatchback
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="d-price text-center">
                            <span>
                              <i className="fa fa-rupee"></i> {car?.totalFare}
                            </span>
                            {car?.kmLimit && <p>Uptp {car?.kmLimit} Km</p>}
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </div>

                    <div className="spacer-20"></div>
                    <h4>Contact</h4>
                    <div className="row g-4">
                      <div className="col-lg-4">
                        <div className="field-set">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className="form-control"
                            placeholder="Your Name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="field-set">
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className="form-control"
                            placeholder="Your Email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                          {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="field-set">
                          <input
                            type="text"
                            name="phone"
                            id="phone"
                            className="form-control"
                            placeholder="Your Phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                          />
                          {errors.phone && <p style={{ color: "red" }}>{errors.phone}</p>}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="field-set">
                          <textarea
                            name="remark"
                            id="remark"
                            className="form-control"
                            placeholder="Remark [If any]"
                            value={formData.remark}
                            onChange={handleChange}
                            style={{ height: "80px" }}
                          ></textarea>
                        </div>
                      </div>
                      <input
                        type="text"
                        name="honeypot"
                        style={{ display: "none" }}
                        value={formData.honeypot}
                        onChange={handleChange}
                      />
                    </div>
                    <div style={{ textAlign: "right", marginTop: "10px" }}>
                      <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                        Book Now
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="de-price text-center">
                Summary of Charges
                {car?.modelName && <p>{car?.modelName}</p>}
                {DUTY_TYPE?.[car?.subType] && <p>{DUTY_TYPE?.[car?.subType]}</p>}
                {car?.durationText && car?.duration > 0 && (
                  <p>Duration: {car?.durationText} Approx</p>
                )}
                {car?.distanceText && car?.distance > 0 && (
                  <p>Distance: {car?.distanceText} Approx</p>
                )}
                {car?.localPackagesStr && <p>Package: {car?.localPackagesStr}</p>}
                {car?.kmLimit && <p>Uptp {car?.kmLimit} Km</p>}
                {car?.totalFare && (
                  <h4>
                    <i className="fa fa-rupee"></i> {car?.totalFare}
                  </h4>
                )}
                <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                  Book Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BookingPage;
