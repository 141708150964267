export const APPNAME = "GardenCityCabs";
export const APIURL = "https://bookingmaster.online/api/";
// export const WEBAPPID =
//   "0aec8b96bd1f952fba2a70bebb9fb0d3c162363eef17064d5579672dae97a675Nl4mKmRlbW8tYWNjb3VudA=="; //Demo 6
export const WEBAPPID =
  "eacd60899b1e398c987740e6e87892d97d29610a7c1d0230c4e1235c96db57b7MTVeJipnYXJkZW4tY2l0eS1jYWJz"; //15

export const isEmpty = (value) => {
  return value === undefined || value === null || value === "";
};

export const GOOGLE_MAP_API = "AIzaSyCnSHOZSe98FjP-H6iVipMUWriA8IWkKOI";

export const MAP_API_KEY =
  "pk.eyJ1Ijoid2Vibml0ZXIiLCJhIjoiY2x6ZHB4M2o2MGJuejJpczh5YmJyN29maiJ9.v-N_RuzFLFiWO2zpo-Lz9w";

let dutyTypeArray = [];
dutyTypeArray["airportTransferAirportDrop"] = "Airport Transfer - Airport Drop";
dutyTypeArray["airportTransferCityDrop"] = "Airport Transfer - City Drop";
dutyTypeArray["localPackages"] = "Local Packages";
dutyTypeArray["outstationOneway"] = "Outstation Oneway";
dutyTypeArray["outstationRoundTrip"] = "Outstation Round Trip";
export const DUTY_TYPE = dutyTypeArray;
