import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App"; // Import the App component

import "./css/bootstrap.min.css";
import "./css/mdb.min.css";
import "./css/plugins.css";
import "./css/colors/scheme-01.css";
import "./css/coloring.css";
import "./css/style.css";

// Create the root element and render the App component
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
