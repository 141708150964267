import React from "react";

const AboutUs = () => {
  return (
    <section id="section-hero" aria-label="section" className="jarallax">
      <h2>AboutUs Page</h2>
      <p>Welcome to the AboutUs page!</p>
    </section>
  );
};

export default AboutUs;
