import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "./axiosInstance";
import useAuthToken from "../hooks/useAuthToken";
import { LoaderContext } from "./LoaderContext";
import { APIURL, DUTY_TYPE, MAP_API_KEY } from "../Constants";

import resultBackgroungImg from "../images/background/2.jpg";
import carImageThumb from "../images/car-thumbs/car-image.png";

import mapboxgl from "mapbox-gl";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Confirmation = () => {
  const token = useAuthToken();
  const { setHideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  const queryParams = useQuery();
  const reference = queryParams.get("reference");
  const [bookingInfo, setBookingInfo] = useState(null);
  const mapContainer = useRef(null);
  const map = useRef(null);
  mapboxgl.accessToken = MAP_API_KEY;

  const getBookingDetails = async () => {
    setHideLoader(false);
    const response = await axios.get(`${APIURL}get_booking_details?booking=${reference}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setHideLoader(true);
    if (response.status === 200) {
      setBookingInfo(response.data);
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (token) {
      getBookingDetails();
    }
  }, [token]);

  useEffect(() => {
    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [77.635, 12.934], // initial map center
      zoom: 10,
    });

    // Add route to map
    map.current.on("load", () => {
      if (bookingInfo?.bookingData?.route) {
        map.current.addSource("route", {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: bookingInfo?.bookingData?.route,
            },
          },
        });

        map.current.addLayer({
          id: "route",
          type: "line",
          source: "route",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#3887be",
            "line-width": 5,
            "line-opacity": 0.75,
          },
        });

        const bounds = new mapboxgl.LngLatBounds();
        bookingInfo?.bookingData?.route?.forEach((coord) => bounds.extend(coord));
        map.current.fitBounds(bounds, { padding: 50 });
      }
    });
  }, [bookingInfo?.bookingData?.route]);

  console.log("MYLOG bookingInfo", bookingInfo);

  return (
    <>
      <section id="subheader" className="cars-result-header jarallax text-light">
        <img src={resultBackgroungImg} className="jarallax-img" alt="" />
      </section>

      <section>
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-12">
              <h2>
                Booking Confirmation <span className="id-color">{reference}</span>
              </h2>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="de-spec">
                  <div className="d-row">
                    {bookingInfo?.guestName && (
                      <p>
                        <strong>Guest Name:</strong> {bookingInfo?.guestName}
                      </p>
                    )}
                    {bookingInfo?.guestNo && (
                      <p>
                        <strong>Guest Mobile:</strong> {bookingInfo?.guestNo}
                      </p>
                    )}
                    {bookingInfo?.guestEmail && (
                      <p>
                        <strong>Guest Email:</strong> {bookingInfo?.guestEmail}
                      </p>
                    )}
                    {DUTY_TYPE?.[bookingInfo?.dutyType] && (
                      <p>
                        <strong>Duty Type:</strong> {DUTY_TYPE?.[bookingInfo?.dutyType]}
                      </p>
                    )}
                    {bookingInfo?.pickupAdd && (
                      <p>
                        <strong>Pickup:</strong> {bookingInfo?.pickupAdd}
                      </p>
                    )}
                    {bookingInfo?.dropAdd && (
                      <p>
                        <strong>Drop:</strong> {bookingInfo?.dropAdd}
                      </p>
                    )}
                    {bookingInfo?.durationText && (
                      <p>
                        <strong>Duration:</strong> {bookingInfo?.durationText} Approx
                      </p>
                    )}
                    {bookingInfo?.localPackagesStr && (
                      <p>
                        <strong>Package:</strong> {bookingInfo?.localPackagesStr}
                      </p>
                    )}
                    {bookingInfo?.distanceText && (
                      <p>
                        <strong>Distance:</strong> {bookingInfo?.distanceText} Approx
                      </p>
                    )}
                    {bookingInfo?.pickupDate && (
                      <p>
                        <strong>Pickup Date & Time:</strong> {bookingInfo?.pickupDate}{" "}
                        {bookingInfo?.pickupTime}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div ref={mapContainer} style={{ width: "100%", height: "300px" }} />
              </div>

              <div className="col-lg-12">
                <div className="de-item-list mb30">
                  <div className="d-img">
                    <img src={carImageThumb} className="img-fluid" alt={bookingInfo?.modelName} />
                  </div>
                  <div className="d-info">
                    <div className="d-text">
                      <h4>{bookingInfo?.modelName}</h4>
                      <div className="d-atr-group">
                        <ul className="d-atr">
                          <li>
                            <span>Seats:</span>4
                          </li>
                          <li>
                            <span>Doors:</span>4
                          </li>
                          <li>
                            <span>Fuel:</span>Petrol
                          </li>
                          <li>
                            <span>Engine:</span>3000
                          </li>
                          <li>
                            <span>Drive:</span>4x4
                          </li>
                          <li>
                            <span>Type:</span>Hatchback
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="d-price text-center">
                    <span>
                      <i className="fa fa-rupee"></i> {bookingInfo?.tripAmount}
                    </span>
                    {bookingInfo?.kmLimit && <p>Uptp {bookingInfo?.kmLimit} Km</p>}
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Confirmation;
