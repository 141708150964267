import { useEffect, useState } from "react";
import axios from "../Components/axiosInstance";
import { WEBAPPID, APIURL } from "../Constants";

const generateToken = async () => {
  try {
    const response = await axios.get(`${APIURL}generate_token?webapp_id=${WEBAPPID}`);
    const token = response.data.token;
    sessionStorage.setItem("authToken", token);
    return token;
  } catch (error) {
    console.error("Error generating token:", error);
    return null;
  }
};

const validateToken = async (token) => {
  try {
    const response = await axios.get(
      `${APIURL}validate_token`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data.isValid;
  } catch (error) {
    console.error("Error validating token:", error);
    return false;
  }
};

const useAuthToken = () => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    const init = async () => {
      let token = sessionStorage.getItem("authToken");
      if (token) {
        const isValid = await validateToken(token);
        if (!isValid) {
          token = await generateToken();
        }
      } else {
        token = await generateToken();
      }
      setToken(token);
    };
    init();
  }, []);

  return token;
};

export default useAuthToken;
